<template>
  <div>
    <b-alert v-model="isDraftAvailable" v-height-fade.appear variant="warning">
      <div class="alert-body">
        <b-row>
          <b-col class="align-self-center">
            Do you want to restore the draft data?
          </b-col>
          <b-col class="align-self-center text-right">
            <b-button @click="removeDraft" variant="outline-danger" class="mr-2"
              >Ignore</b-button
            >
            <b-button @click="restoreDraft" variant="primary">Restore</b-button>
          </b-col>
        </b-row>
      </div>
    </b-alert>

    <b-tabs>
      <b-tab active title="English">
        <b-card title="Property Details Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Description">
          <b-col cols="12">
            <quill-editor v-model="page.description" :options="snowOption" />
          </b-col>
        </b-card>

        <b-card title="Property Details List">
          <b-form
            ref="descriptionEngForm"
            class="repeater-form"
            @submit.prevent="repeatProperty(null)"
          >
            <b-row
              v-for="(property, index) in page.propertyDetailsList"
              :key="`category-english-${index}`"
              ref="propertRow"
            >
              <b-col cols="10">
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Property Name">
                      <b-form-input
                        v-model="page.propertyDetailsList[index].propertyName"
                        placeholder="propertyName"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Area">
                      <b-form-input
                        v-model="page.propertyDetailsList[index].area"
                        placeholder="Area"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Details">
                      <b-form-input
                        v-model="page.propertyDetailsList[index].details"
                        placeholder="Details"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Title Name">
                      <b-form-input
                        v-model="page.propertyDetailsList[index].titleName"
                        placeholder="Title Name"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="City">
                      <b-form-input
                        v-model="page.propertyDetailsList[index].city"
                        placeholder="City"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Property Type">
                      <b-form-input
                        v-model="page.propertyDetailsList[index].propertyType"
                        placeholder="Property Type"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col v-if="!hasNoDateOfPossession.en[index]" cols="6">
                    <b-form-group label="Date Of Possession">
                      <b-calendar
                        v-model="
                          page.propertyDetailsList[index].dateOfPossession
                        "
                        block
                        locale="en-US"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-checkbox
                      checked="true"
                      
                      v-model="hasNoDateOfPossession.en[index]"
                      name="check-button"
                      class="mb-1 mt-1"
                      switch
                      inline
                      >Disable Date</b-form-checkbox
                    >
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeProperty(null, index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatProperty(null)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.propertyDetailsList.length === 0
                      ? "Add property detail"
                      : "Add New property detail"
                  }}</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

        <b-card title="Lease Property Details">
          <b-row>
            <b-col cols="12">
              <b-tabs>
                <b-tab title="Long Term Lease">
                  <b-form
                    ref="longtermLeasee"
                    class="repeater-form"
                    @submit.prevent="repeatLongTermLease(null)"
                  >
                    <b-row
                      v-for="(leasee, index) in page.leaseeProperty.LongTerm"
                      :key="`category-english-${index}`"
                      ref="propertRow"
                    >
                      <b-col cols="10">
                        <b-form-group label="Property Name">
                          <b-form-input
                            v-model="
                              page.leaseeProperty.LongTerm[index].propertyName
                            "
                            placeholder="propertyName"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="2" class="align-self-center">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          @click="removeLongTermLease(null, index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                        </b-button>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Lease Name">
                          <b-form-input
                            v-model="
                              page.leaseeProperty.LongTerm[index].leaseeName
                            "
                            placeholder="Lease Name"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Period">
                          <b-form-input
                            v-model="page.leaseeProperty.LongTerm[index].period"
                            placeholder="Period"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12">
                        <b-form-group label="Agreement">
                          <b-row>
                            <b-col cols="6">
                              <b-form-checkbox
                                checked="true"
                                v-model="hasNoDate.LongTerm[index]"
                                name="check-button"
                                class="mb-1 mt-1"
                                switch
                                inline
                                >Disable Date</b-form-checkbox
                              >
                            </b-col>
                          </b-row>
                          <b-row v-if="!hasNoDate.LongTerm[index]">
                            <b-col cols="6">
                              <b-calendar
                                v-model="
                                  page.leaseeProperty.LongTerm[index].agreement
                                    .startDate
                                "
                                block
                                locale="en-US"
                              />
                            </b-col>
                            <b-col cols="6">
                              <b-calendar
                                v-model="
                                  page.leaseeProperty.LongTerm[index].agreement
                                    .endDate
                                "
                                block
                                locale="en-US"
                              />
                            </b-col>
                            <b-col cols="12">
                              <hr />
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="mt-1"
                        ><b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          @click="repeatLongTermLease(null)"
                        >
                          <feather-icon icon="PlusIcon" class="mr-25" />
                          <span>{{
                            page.leaseeProperty.LongTerm.length === 0
                              ? "Add LongTerm Leasee"
                              : "Add New LongTerm Leasee"
                          }}</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-tab>
                <b-tab title="Short Term Lease">
                  <b-form
                    ref="longtermLeasee"
                    class="repeater-form"
                    @submit.prevent="repeatShortTermLease(null)"
                  >
                    <b-row
                      v-for="(leasee, index) in page.leaseeProperty.ShortTerm"
                      :key="`category-english-${index}`"
                      ref="propertRow"
                    >
                      <b-col cols="10">
                        <b-form-group label="Property Name">
                          <b-form-input
                            v-model="
                              page.leaseeProperty.ShortTerm[index].propertyName
                            "
                            placeholder="propertyName"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="2" class="align-self-center">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          @click="removeShortTermLease(null, index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                        </b-button>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Lease Name">
                          <b-form-input
                            v-model="
                              page.leaseeProperty.ShortTerm[index].leaseeName
                            "
                            placeholder="Lease Name"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Period">
                          <b-form-input
                            v-model="
                              page.leaseeProperty.ShortTerm[index].period
                            "
                            placeholder="Period"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12">
                        <b-form-group label="Agreement">
                          <b-row>
                            <b-col cols="6">
                              <b-form-checkbox
                                checked="true"
                                v-model="hasNoDate.ShortTerm[index]"
                                name="check-button"
                                class="mb-1 mt-1"
                                switch
                                inline
                                >Disable Date</b-form-checkbox
                              >
                            </b-col>
                          </b-row>
                          <b-row v-if="!hasNoDate.ShortTerm[index]">
                            <b-col cols="6">
                              <b-calendar
                                v-model="
                                  page.leaseeProperty.ShortTerm[index].agreement
                                    .startDate
                                "
                                block
                                locale="en-US"
                              />
                            </b-col>
                            <b-col cols="6">
                              <b-calendar
                                v-model="
                                  page.leaseeProperty.ShortTerm[index].agreement
                                    .endDate
                                "
                                block
                                locale="en-US"
                              />
                            </b-col>
                            <b-col cols="12">
                              <hr />
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="mt-1"
                        ><b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          @click="repeatShortTermLease(null)"
                        >
                          <feather-icon icon="PlusIcon" class="mr-25" />
                          <span>{{
                            page.leaseeProperty.ShortTerm.length === 0
                              ? "Add ShortTerm Leasee"
                              : "Add New ShortTerm Leasee"
                          }}</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Notes">
          <b-row>
            <b-col cols="12">
              <ckeditor v-model="page.notes"></ckeditor>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Disclaimer">
          <b-row>
            <b-col cols="12">
              <ckeditor v-model="page.disclaimer"></ckeditor>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
      <!-- Hindi Translation -->
      <b-tab title="Hindi">
        <b-card title="Property Details Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.translation.hi.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Description">
          <b-col cols="12">
            <quill-editor
              v-model="page.translation.hi.description"
              :options="snowOption"
            />
          </b-col>
        </b-card>

        <b-card title="Property Details List">
          <b-form
            ref="descriptionHiForm"
            class="repeater-form"
            @submit.prevent="repeatProperty('hi')"
          >
            <b-row
              v-for="(property, index) in page.translation.hi.propertyDetailsList"
              :key="index"
              ref="propertRow"
            >
              <b-col cols="10">
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Property Name">
                      <b-form-input
                        v-model="
                          page.translation.hi.propertyDetailsList[index]
                            .propertyName
                        "
                        placeholder="propertyName"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Area">
                      <b-form-input
                        v-model="
                          page.translation.hi.propertyDetailsList[index].area
                        "
                        placeholder="Area"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Details">
                      <b-form-input
                        v-model="
                          page.translation.hi.propertyDetailsList[index].details
                        "
                        placeholder="Details"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Title Name">
                      <b-form-input
                        v-model="
                          page.translation.hi.propertyDetailsList[index]
                            .titleName
                        "
                        placeholder="Title Name"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="City">
                      <b-form-input
                        v-model="
                          page.translation.hi.propertyDetailsList[index].city
                        "
                        placeholder="City"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Property Type">
                      <b-form-input
                        v-model="
                          page.translation.hi.propertyDetailsList[index]
                            .propertyType
                        "
                        placeholder="Property Type"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col v-if="!hasNoDateOfPossession.hi[index]" cols="6">
                    <b-form-group label="Date Of Possession">
                      <b-calendar
                        v-model="
                          page.translation.hi.propertyDetailsList[index]
                            .dateOfPossession
                        "
                        block
                        locale="en-US"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-checkbox
                      checked="true"
                      v-model="hasNoDateOfPossession.hi[index]"
                      name="check-button"
                      class="mb-1 mt-1"
                      switch
                      inline
                      >Disable Date</b-form-checkbox
                    >
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeProperty('hi', index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatProperty('hi')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.translation.hi.propertyDetailsList.length === 0
                      ? "Add property detail"
                      : "Add New property detail"
                  }}</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

        <b-card title="Lease Property Details">
          <b-row>
            <b-col cols="12">
              <b-tabs>
                <b-tab title="Long Term Lease">
                  <b-form
                    ref="longtermLeasee"
                    class="repeater-form"
                    @submit.prevent="repeatLongTermLease('hi')"
                  >
                    <b-row
                      v-for="(leasee, index) in page.translation.hi
                        .leaseeProperty.LongTerm"
                      :key="`category-english-${index}`"
                      ref="propertRow"
                    >
                      <b-col cols="10">
                        <b-form-group label="Property Name">
                          <b-form-input
                            v-model="
                              page.translation.hi.leaseeProperty.LongTerm[index]
                                .propertyName
                            "
                            placeholder="propertyName"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="2" class="align-self-center">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          @click="removeLongTermLease('hi', index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                        </b-button>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Lease Name">
                          <b-form-input
                            v-model="
                              page.translation.hi.leaseeProperty.LongTerm[index]
                                .leaseeName
                            "
                            placeholder="Lease Name"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Period">
                          <b-form-input
                            v-model="
                              page.translation.hi.leaseeProperty.LongTerm[index]
                                .period
                            "
                            placeholder="Period"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12">
                        <b-form-group label="Agreement">
                          <b-row>
                            <b-col cols="6">
                              <b-form-checkbox
                                checked="true"
                                v-model="
                                  hasNoDate.translation.hi.LongTerm[index]
                                "
                                name="check-button"
                                class="mb-1 mt-1"
                                switch
                                inline
                                >Disable Date</b-form-checkbox
                              >
                            </b-col>
                          </b-row>
                          <b-row
                            v-if="!hasNoDate.translation.hi.LongTerm[index]"
                          >
                            <b-col cols="6">
                              <b-calendar
                                v-model="
                                  page.translation.hi.leaseeProperty.LongTerm[
                                    index
                                  ].agreement.startDate
                                "
                                block
                                locale="en-US"
                              />
                            </b-col>
                            <b-col cols="6">
                              <b-calendar
                                v-model="
                                  page.translation.hi.leaseeProperty.LongTerm[
                                    index
                                  ].agreement.endDate
                                "
                                block
                                locale="en-US"
                              />
                            </b-col>
                            <b-col cols="12">
                              <hr />
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="mt-1"
                        ><b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          @click="repeatLongTermLease('hi')"
                        >
                          <feather-icon icon="PlusIcon" class="mr-25" />
                          <span>{{
                            page.translation.hi.leaseeProperty.LongTerm
                              .length === 0
                              ? "Add LongTerm Leasee"
                              : "Add New LongTerm Leasee"
                          }}</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-tab>
                <b-tab title="Short Term Lease">
                  <b-form
                    ref="longtermLeasee"
                    class="repeater-form"
                    @submit.prevent="repeatShortTermLease('hi')"
                  >
                    <b-row
                      v-for="(leasee, index) in page.translation.hi
                        .leaseeProperty.ShortTerm"
                      :key="`category-english-${index}`"
                      ref="propertRow"
                    >
                      <b-col cols="10">
                        <b-form-group label="Property Name">
                          <b-form-input
                            v-model="
                              page.translation.hi.leaseeProperty.ShortTerm[
                                index
                              ].propertyName
                            "
                            placeholder="propertyName"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="2" class="align-self-center">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          @click="removeShortTermLease('hi', index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                        </b-button>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Lease Name">
                          <b-form-input
                            v-model="
                              page.translation.hi.leaseeProperty.ShortTerm[
                                index
                              ].leaseeName
                            "
                            placeholder="Lease Name"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Period">
                          <b-form-input
                            v-model="
                              page.translation.hi.leaseeProperty.ShortTerm[
                                index
                              ].period
                            "
                            placeholder="Period"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12">
                        <b-form-group label="Agreement">
                          <b-row>
                            <b-col cols="6">
                              <b-form-checkbox
                                checked="true"
                                v-model="
                                  hasNoDate.translation.hi.ShortTerm[index]
                                "
                                name="check-button"
                                class="mb-1 mt-1"
                                switch
                                inline
                                >Disable Date</b-form-checkbox
                              >
                            </b-col>
                          </b-row>
                          <b-row
                            v-if="!hasNoDate.translation.hi.ShortTerm[index]"
                          >
                            <b-col cols="6">
                              <b-calendar
                                v-model="
                                  page.translation.hi.leaseeProperty.ShortTerm[
                                    index
                                  ].agreement.startDate
                                "
                                block
                                locale="en-US"
                              />
                            </b-col>
                            <b-col cols="6">
                              <b-calendar
                                v-model="
                                  page.translation.hi.leaseeProperty.ShortTerm[
                                    index
                                  ].agreement.endDate
                                "
                                block
                                locale="en-US"
                              />
                            </b-col>
                            <b-col cols="12">
                              <hr />
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="mt-1"
                        ><b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          @click="repeatShortTermLease('hi')"
                        >
                          <feather-icon icon="PlusIcon" class="mr-25" />
                          <span>{{
                            page.translation.hi.leaseeProperty.ShortTerm
                              .length === 0
                              ? "Add ShortTerm Leasee"
                              : "Add New ShortTerm Leasee"
                          }}</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Notes">
          <b-row>
            <b-col cols="12">
              <ckeditor v-model="page.translation.hi.notes"></ckeditor>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Disclaimer">
          <b-row>
            <b-col cols="12">
              <ckeditor v-model="page.translation.hi.disclaimer"></ckeditor>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
      <!-- Marathi Translation -->
      <b-tab title="Marathi">
        <b-card title="Property Details Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.translation.mr.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Description">
          <b-col cols="12">
            <quill-editor
              v-model="page.translation.mr.description"
              :options="snowOption"
            />
          </b-col>
        </b-card>

        <b-card title="Property Details List">
          <b-form
            ref="descriptionMrForm"
            class="repeater-form"
            @submit.prevent="repeatProperty('mr')"
          >
            <b-row
              v-for="(property, index) in page.translation.mr
                .propertyDetailsList"
              :key="`category-english-${index}`"
              ref="propertRow"
            >
              <b-col cols="10">
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Property Name">
                      <b-form-input
                        v-model="
                          page.translation.mr.propertyDetailsList[index]
                            .propertyName
                        "
                        placeholder="propertyName"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Area">
                      <b-form-input
                        v-model="
                          page.translation.mr.propertyDetailsList[index].area
                        "
                        placeholder="Area"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Details">
                      <b-form-input
                        v-model="
                          page.translation.mr.propertyDetailsList[index].details
                        "
                        placeholder="Details"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Title Name">
                      <b-form-input
                        v-model="
                          page.translation.mr.propertyDetailsList[index]
                            .titleName
                        "
                        placeholder="Title Name"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="City">
                      <b-form-input
                        v-model="
                          page.translation.mr.propertyDetailsList[index].city
                        "
                        placeholder="City"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Property Type">
                      <b-form-input
                        v-model="
                          page.translation.mr.propertyDetailsList[index]
                            .propertyType
                        "
                        placeholder="Property Type"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col v-if="!hasNoDateOfPossession.mr[index]" cols="6">
                    <b-form-group label="Date Of Possession">
                      <b-calendar
                        v-model="
                          page.translation.mr.propertyDetailsList[index]
                            .dateOfPossession
                        "
                        block
                        locale="en-US"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-checkbox
                      checked="true"
                      v-model="hasNoDateOfPossession.mr[index]"
                      name="check-button"
                      class="mb-1 mt-1"
                      switch
                      inline
                      >Disable Date</b-form-checkbox
                    >
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeProperty('mr', index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatProperty('mr')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.translation.mr.propertyDetailsList.length === 0
                      ? "Add property detail"
                      : "Add New property detail"
                  }}</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

        <b-card title="Lease Property Details">
          <b-row>
            <b-col cols="12">
              <b-tabs>
                <b-tab title="Long Term Lease">
                  <b-form
                    ref="longtermLeasee"
                    class="repeater-form"
                    @submit.prevent="repeatLongTermLease('mr')"
                  >
                    <b-row
                      v-for="(leasee, index) in page.translation.mr
                        .leaseeProperty.LongTerm"
                      :key="`category-english-${index}`"
                      ref="propertRow"
                    >
                      <b-col cols="10">
                        <b-form-group label="Property Name">
                          <b-form-input
                            v-model="
                              page.translation.mr.leaseeProperty.LongTerm[index]
                                .propertyName
                            "
                            placeholder="propertyName"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="2" class="align-self-center">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          @click="removeLongTermLease('mr', index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                        </b-button>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Lease Name">
                          <b-form-input
                            v-model="
                              page.translation.mr.leaseeProperty.LongTerm[index]
                                .leaseeName
                            "
                            placeholder="Lease Name"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Period">
                          <b-form-input
                            v-model="
                              page.translation.mr.leaseeProperty.LongTerm[index]
                                .period
                            "
                            placeholder="Period"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12">
                        <b-form-group label="Agreement">
                          <b-row>
                            <b-col cols="6">
                              <b-form-checkbox
                                checked="true"
                                v-model="
                                  hasNoDate.translation.mr.LongTerm[index]
                                "
                                name="check-button"
                                class="mb-1 mt-1"
                                switch
                                inline
                                >Disable Date</b-form-checkbox
                              >
                            </b-col>
                          </b-row>
                          <b-row
                            v-if="!hasNoDate.translation.mr.LongTerm[index]"
                          >
                            <b-col cols="6">
                              <b-calendar
                                v-model="
                                  page.translation.mr.leaseeProperty.LongTerm[
                                    index
                                  ].agreement.startDate
                                "
                                block
                                locale="en-US"
                              />
                            </b-col>
                            <b-col cols="6">
                              <b-calendar
                                v-model="
                                  page.translation.mr.leaseeProperty.LongTerm[
                                    index
                                  ].agreement.endDate
                                "
                                block
                                locale="en-US"
                              />
                            </b-col>
                            <b-col cols="12">
                              <hr />
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="mt-1"
                        ><b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          @click="repeatLongTermLease('mr')"
                        >
                          <feather-icon icon="PlusIcon" class="mr-25" />
                          <span>{{
                            page.translation.mr.leaseeProperty.LongTerm
                              .length === 0
                              ? "Add LongTerm Leasee"
                              : "Add New LongTerm Leasee"
                          }}</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-tab>
                <b-tab title="Short Term Lease">
                  <b-form
                    ref="longtermLeasee"
                    class="repeater-form"
                    @submit.prevent="repeatShortTermLease('mr')"
                  >
                    <b-row
                      v-for="(leasee, index) in page.translation.mr
                        .leaseeProperty.ShortTerm"
                      :key="`category-english-${index}`"
                      ref="propertRow"
                    >
                      <b-col cols="10">
                        <b-form-group label="Property Name">
                          <b-form-input
                            v-model="
                              page.translation.mr.leaseeProperty.ShortTerm[
                                index
                              ].propertyName
                            "
                            placeholder="propertyName"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="2" class="align-self-center">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          @click="removeShortTermLease('mr', index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                        </b-button>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Lease Name">
                          <b-form-input
                            v-model="
                              page.translation.mr.leaseeProperty.ShortTerm[
                                index
                              ].leaseeName
                            "
                            placeholder="Lease Name"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Period">
                          <b-form-input
                            v-model="
                              page.translation.mr.leaseeProperty.ShortTerm[
                                index
                              ].period
                            "
                            placeholder="Period"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12">
                        <b-form-group label="Agreement">
                          <b-row>
                            <b-col cols="6">
                              <b-form-checkbox
                                checked="true"
                                v-model="
                                  hasNoDate.translation.mr.ShortTerm[index]
                                "
                                name="check-button"
                                class="mb-1 mt-1"
                                switch
                                inline
                                >Disable Date</b-form-checkbox
                              >
                            </b-col>
                          </b-row>
                          <b-row
                            v-if="!hasNoDate.translation.mr.ShortTerm[index]"
                          >
                            <b-col cols="6">
                              <b-calendar
                                v-model="
                                  page.translation.mr.leaseeProperty.ShortTerm[
                                    index
                                  ].agreement.startDate
                                "
                                block
                                locale="en-US"
                              />
                            </b-col>
                            <b-col cols="6">
                              <b-calendar
                                v-model="
                                  page.translation.mr.leaseeProperty.ShortTerm[
                                    index
                                  ].agreement.endDate
                                "
                                block
                                locale="en-US"
                              />
                            </b-col>
                            <b-col cols="12">
                              <hr />
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="mt-1"
                        ><b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          @click="repeatShortTermLease('mr')"
                        >
                          <feather-icon icon="PlusIcon" class="mr-25" />
                          <span>{{
                            page.translation.mr.leaseeProperty.ShortTerm
                              .length === 0
                              ? "Add ShortTerm Leasee"
                              : "Add New ShortTerm Leasee"
                          }}</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Notes">
          <b-row>
            <b-col cols="12">
              <ckeditor v-model="page.translation.mr.notes"></ckeditor>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Disclaimer">
          <b-row>
            <b-col cols="12">
              <ckeditor v-model="page.translation.mr.disclaimer"></ckeditor>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
    </b-tabs>

    <b-card>
      <b-row>
        <b-col cols="6">
          <b-button size="lg" @click="saveDraft" block variant="outline-info"
            >Create Draft</b-button
          >
        </b-col>
        <b-col cols="6">
          <b-button size="lg" @click="savePage" block variant="primary"
            >Save Page</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BRow,
  BCol,
  BCalendar,
  BForm,
  BFormInput,
  BFormCheckbox,
  BFormGroup,
  BTabs,
  BTab,
  BButton,
  BFormTextarea,
  BImg,
  BAlert,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import { heightFade } from "@core/directives/animations";
import { getUserToken } from "@/auth/utils";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import axios from "axios";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  directives: {
    "height-fade": heightFade,
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BCalendar,
    BFormCheckbox,
    BFormGroup,
    quillEditor,
    BTabs,
    BTab,
    BButton,
    BFormTextarea,
    ComponentMediaSelector,
    BImg,
    BAlert,
  },
  data() {
    return {
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote"],
            ["link"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            ["clean"],
          ],
        },
      },
      hasNoDateOfPossession: {
        en: [],
        hi: [],
        mr: [],
      },
      hasNoDate: {
        LongTerm: [],
        ShortTerm: [],
        translation: {
          hi: {
            LongTerm: [],
            ShortTerm: [],
          },
          mr: {
            LongTerm: [],
            ShortTerm: [],
          },
        },
      },
      isDraftAvailable: false,
      page: {
        pageTitle: "",
        description: "",
        propertyDetailsList: [],
        leaseeProperty: {
          LongTerm: [],
          ShortTerm: [],
        },
        notes: "",
        disclaimer: "",
        translation: {
          hi: {
            pageTitle: "",
            description: "",
            propertyDetailsList: [],
            leaseeProperty: {
              LongTerm: [],
              ShortTerm: [],
            },
            notes: "",
            disclaimer: "",
          },
          mr: {
            pageTitle: "",
            description: "",
            propertyDetailsList: [],
            leaseeProperty: {
              LongTerm: [],
              ShortTerm: [],
            },
            notes: "",
            disclaimer: "",
          },
        },
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    repeatProperty(lang = undefined) {
      if (lang) {
        console.log(lang)
        this.hasNoDateOfPossession[lang].push(false);
        console.log(this.hasNoDateOfPossession)
        this.page.translation[lang].propertyDetailsList.push({
          propertyName: "",
          area: "",
          dateOfPossession: "",
          details: "",
          titleName: "",
          city: "",
          propertyType: "",
        });
        console.log(this.page)
      } else {
        this.hasNoDateOfPossession["en"].push(false);
        this.page.propertyDetailsList.push({
          propertyName: "",
          area: "",
          dateOfPossession: "",
          details: "",
          titleName: "",
          city: "",
          propertyType: "",
        });
      }
    },
    repeatLongTermLease(lang = undefined) {
      if(lang) {
        this.hasNoDate.translation[lang].LongTerm.push(false);
      this.page.translation[lang].leaseeProperty.LongTerm.push({
        propertyName: "",
        leaseeName: "",
        period: "",
        agreement: {
          startDate: "",
          endDate: "",
        },
      });
      } else {
        this.hasNoDate.LongTerm.push(false);
      this.page.leaseeProperty.LongTerm.push({
        propertyName: "",
        leaseeName: "",
        period: "",
        agreement: {
          startDate: "",
          endDate: "",
        },
      });
      }
      
    },
    repeatShortTermLease(lang = undefined) {
      if (lang) {
        this.hasNoDate.translation[lang].ShortTerm.push(false);
        this.page.translation[lang].leaseeProperty.ShortTerm.push({
          propertyName: "",
          leaseeName: "",
          period: "",
          agreement: {
            startDate: "",
            endDate: "",
          },
        });
      } else {
        this.hasNoDate.ShortTerm.push(false);
        this.page.leaseeProperty.ShortTerm.push({
          propertyName: "",
          leaseeName: "",
          period: "",
          agreement: {
            startDate: "",
            endDate: "",
          },
        });
      }
    },
    removeProperty(lang = undefined, index) {
      if (lang) {
        this.hasNoDateOfPossession[lang].splice(index, 1);
        this.page.translation[lang].propertyDetailsList.splice(index, 1);
      } else {
        this.hasNoDateOfPossession["en"].splice(index, 1);
        this.page.propertyDetailsList.splice(index, 1);
      }
    },
    removeLongTermLease(lang = undefined, index) {
      if (lang) {
        this.hasNoDate.translation[lang].LongTerm.splice(index, 1);
        this.page.translation[lang].leaseeProperty.LongTerm.splice(index, 1);
      } else {
        this.hasNoDate.LongTerm.splice(index, 1);
        this.page.leaseeProperty.LongTerm.splice(index, 1);
      }
    },
    removeShortTermLease(lang = undefined, index) {
      if (lang) {
        this.hasNoDate.translation[lang].ShortTerm.splice(index, 1);
        this.page.translation[lang].leaseeProperty.ShortTerm.splice(index, 1);
      } else {
        this.hasNoDate.ShortTerm.splice(index, 1);
        this.page.leaseeProperty.ShortTerm.splice(index, 1);
      }
    },
    saveDraft() {
      localStorage.setItem("property-details-data", JSON.stringify(this.page));
      this.makeToast("success", "Success", `Draft Saved!`);
    },
    restoreDraft() {
      const page = localStorage.getItem("property-details-data");
      this.page = JSON.parse(page);
    },
    removeDraft() {
      localStorage.removeItem("property-details-data");
      this.isDraftAvailable = false;
    },
    getPage() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/pages/propertyDetails/all`)
        .then((response) => {
          if (response.data.length > 0) {
            this.page = response.data[0];
            const { translation } = response.data[0];
            this.page.translation = {
              hi: {
                pageTitle:
                  translation && translation.hi.pageTitle
                    ? translation.hi.pageTitle
                    : "",
                description:
                  translation && translation.hi.description
                    ? translation.hi.description
                    : "",
                propertyDetailsList:
                  translation && translation.hi.propertyDetailsList
                    ? translation.hi.propertyDetailsList
                    : [],
                leaseeProperty: {
                  LongTerm:
                    translation &&
                    translation.hi.leaseeProperty &&
                    translation.hi.leaseeProperty.LongTerm
                      ? translation.hi.leaseeProperty.LongTerm
                      : [],
                  ShortTerm:
                    translation &&
                    translation.hi.leaseeProperty &&
                    translation.hi.leaseeProperty.ShortTerm
                      ? translation.hi.leaseeProperty.ShortTerm
                      : [],
                },
                notes:
                  translation && translation.hi.notes
                    ? translation.hi.notes
                    : "",
                disclaimer:
                  translation && translation.hi.disclaimer
                    ? translation.hi.disclaimer
                    : "",
              },
              mr: {
                pageTitle:
                  translation && translation.mr.pageTitle
                    ? translation.mr.pageTitle
                    : "",
                description:
                  translation && translation.mr.description
                    ? translation.mr.description
                    : "",
                propertyDetailsList:
                  translation && translation.mr.propertyDetailsList
                    ? translation.mr.propertyDetailsList
                    : [],
                leaseeProperty: {
                  LongTerm:
                    translation &&
                    translation.mr.leaseeProperty &&
                    translation.mr.leaseeProperty.LongTerm
                      ? translation.mr.leaseeProperty.LongTerm
                      : [],
                  ShortTerm:
                    translation &&
                    translation.mr.leaseeProperty &&
                    translation.mr.leaseeProperty.ShortTerm
                      ? translation.mr.leaseeProperty.ShortTerm
                      : [],
                },
                notes:
                  translation && translation.mr.notes
                    ? translation.mr.notes
                    : "",
                disclaimer:
                  translation && translation.mr.disclaimer
                    ? translation.mr.disclaimer
                    : "",
              },
            };
            console.log('page final', this.page);
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    savePage() {
      for (let i in this.page.propertyDetailsList) {
        if (this.hasNoDateOfPossession.en[i]) {
          this.page.propertyDetailsList[i].dateOfPossession = "";
        }
      }
      for (let i in this.page.translation.hi.propertyDetailsList) {
        if (this.hasNoDateOfPossession.hi[i]) {
          this.page.translation.hi.propertyDetailsList[i].dateOfPossession = "";
        }
      }
      for (let i in this.page.translation.mr.propertyDetailsList) {
        if (this.hasNoDateOfPossession.mr[i]) {
          this.page.translation.mr.propertyDetailsList[i].dateOfPossession = "";
        }
      } 

      for (let i in this.page.leaseeProperty.LongTerm) {
        if (this.hasNoDate.LongTerm[i]) {
          this.page.leaseeProperty.LongTerm[i].dateOfPossession = "";
        }
      }
      for (let i in this.page.translation.hi.leaseeProperty.LongTerm) {
        if (this.hasNoDate.translation.hi.LongTerm[i]) {
          this.page.translation.hi.leaseeProperty.LongTerm[i].dateOfPossession = "";
        }
      }
      for (let i in this.page.translation.mr.leaseeProperty.LongTerm) {
        if (this.hasNoDate.translation.mr.LongTerm[i]) {
          this.page.translation.mr.leaseeProperty.LongTerm[i].dateOfPossession = "";
        }
      } 

      for (let i in this.page.leaseeProperty.ShortTerm) {
        if (this.hasNoDate.ShortTerm[i]) {
          this.page.leaseeProperty.ShortTerm[i].dateOfPossession = "";
        }
      }
      for (let i in this.page.translation.hi.leaseeProperty.ShortTerm) {
        if (this.hasNoDate.translation.hi.ShortTerm[i]) {
          this.page.translation.hi.leaseeProperty.ShortTerm[i].dateOfPossession = "";
        }
      }
      for (let i in this.page.translation.mr.leaseeProperty.ShortTerm) {
        if (this.hasNoDate.translation.mr.ShortTerm[i]) {
          this.page.translation.mr.leaseeProperty.ShortTerm[i].dateOfPossession = "";
        }
      } 
      console.log(this.page);
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/pages/propertyDetails/save`,
          this.page,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          console.log("res", response.data);
          this.makeToast(
            "success",
            "Success",
            `${this.page.pageTitle} has been updated successfully!`
          );
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Error while updating the event!");
        });
    },
  },
  mounted() {
    if (localStorage.getItem("property-details-data")) {
      this.isDraftAvailable = true;
    }
    this.getPage();
  },
};
</script>

<style>
</style>